import React from "react";
import Header from "../../components/bc/Header";
import LinkList from "../../components/bc/LinkList"
import ShareButton from "../../components/bc/ShareButton"
import TopBar from "../../components/bc/TopBar"
import Footer from "../../components/bc/Footer";
import { Seo as SEO } from "../../components/Seo";

const LinktreePage = ({ data }) => {
    const user = {
        headerImage: "/images/bc/Matheus_Alvin_Prawira.JPG",
        title: "Matheus Alvin Prawira",
        subtitle: "Process Development Sr. Supervisor",
        linkList: [
          {
            links: [
              {label: "Send a message", link: "https://wa.me/6287883149089"},
              {label: "Send an email", link: "mailto:matheus.prawira@bifarma.co.id"},
              // {label: "Official Website", link: "https://regenic.co.id", image: "/images/bc/website-white-icon.png"},
            ]
          },
        ]
    }

    return (
      <main className="bg-dark2 ">
        <div className="relative max-w-[580px] px-4 pt-[100px] md:pb-16 pb-8 mx-auto text-light bg-cover bg-center min-h-screen" style={{backgroundImage: `url("/images/bc/linktree_static_bg.png")`}}>
          { user ? 
          <div className="flex flex-col h-full">
              <img src="/images/bc/logo-white.png" className="mt-2.5 absolute top-0 right-0 w-[240px]"/>
              <TopBar>
                <div className="max-w-[580px] px-8 mx-auto w-full flex justify-start">
                  <ShareButton slug={"b9b25772-1a2a-48f7-afcb-2cebf9c6e283"}/>
                </div>  
              </TopBar>
              <Header {...user}/>
              <div className="flex flex-col mt-6 gap-8">
                {user.linkList.map((list, index) => (
                  <LinkList key={index} {...list}/>
                ))}
              </div>
              <Footer/>
          </div> :
          <div className=" h-[70vh] flex flex-col gap-2 justify-center items-center">
              <p className="text-[120px] font-bold">404</p>
              <p className="text-xl">User not found, please try again later.</p>
          </div>}
        </div>
      </main>
    );
}

export default LinktreePage

export const Head = ({ params }) => {
    return (
      <SEO
        title={'Bifarma Adiluhung | Career'}
        description={
          'Join Bifarma Adiluhung and contribute to our mission of enhancing lives through preventive, promotive, and predictive treatments. Explore rewarding career opportunities in the dynamic biopharmaceutical sector.'
        }
      />
    )
}

